var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",attrs:{"role":"dialog"}},[_vm._m(0),_c('ValidationObserver',{ref:"banTime"},[_c('ValidationProvider',{staticClass:"md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Наимнование:")]),_c('md-input',{model:{value:(_vm.banName),callback:function ($$v) {_vm.banName=$$v},expression:"banName"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("С:")]),_c('DatePicker',{attrs:{"prefix-class":"xmx","type":"date","show-second":false,"lang":"ru","popup-style":{
            left: 0,
          },"append-to-body":false,"value-type":"format","format":"DD.MM.YYYY"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('md-input',{staticClass:"w-full",model:{value:(_vm.dateTill),callback:function ($$v) {_vm.dateTill=$$v},expression:"dateTill"}})]},proxy:true}],null,true),model:{value:(_vm.dateTill),callback:function ($$v) {_vm.dateTill=$$v},expression:"dateTill"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("До:")]),_c('DatePicker',{attrs:{"prefix-class":"xmx","type":"date","show-second":false,"lang":"ru","popup-style":{
            left: 0,
          },"append-to-body":false,"value-type":"format","format":"DD.MM.YYYY"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('md-input',{staticClass:"w-full",attrs:{"value":_vm.dateFrom}})]},proxy:true}],null,true),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)]}}])}),_c('div',{staticClass:"buttons-wrap justify-end"},[_c('md-button',{staticClass:"md-raised md-success",attrs:{"type":"submit"}},[_vm._v(" Подтвердить ")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("Время бана")])])}]

export { render, staticRenderFns }