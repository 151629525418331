<template>
  <div class="modal" role="dialog">
    <div>
      <h4>Время бана</h4>
    </div>

    <ValidationObserver ref="banTime">
      <ValidationProvider
        v-slot="{ errors }"
        class="md-size-100"
        mode="passive"
        tag="div"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>Наимнование:</label>
          <md-input v-model="banName" />
        </md-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        class="md-size-100"
        mode="passive"
        tag="div"
        rules="required"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>С:</label>
          <DatePicker
            v-model="dateTill"
            prefix-class="xmx"
            type="date"
            :show-second="false"
            lang="ru"
            :popup-style="{
              left: 0,
            }"
            :append-to-body="false"
            value-type="format"
            format="DD.MM.YYYY"
          >
            <template #input>
              <md-input v-model="dateTill" class="w-full" />
            </template>
          </DatePicker>
        </md-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        class="md-size-100"
        mode="passive"
        tag="div"
        rules="required"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>До:</label>
          <DatePicker
            v-model="dateFrom"
            prefix-class="xmx"
            type="date"
            :show-second="false"
            lang="ru"
            :popup-style="{
              left: 0,
            }"
            :append-to-body="false"
            value-type="format"
            format="DD.MM.YYYY"
          >
            <template #input>
              <md-input :value="dateFrom" class="w-full" />
            </template>
          </DatePicker>
        </md-field>
      </ValidationProvider>

      <div class="buttons-wrap justify-end">
        <md-button class="md-raised md-success" type="submit">
          Подтвердить
        </md-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      dateTill: null,
      dateFrom: null,
      banName: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  height: auto;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  background: #fff;
  min-width: 600px;
}
.xmx-datepicker,
.w-full {
  width: 100%;
}
</style>
